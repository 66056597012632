class ActivityReportsRoot {
  $scope:            any;
  $location:         any;
  errFactory:        any;
  loaderFactory:     any;
  assignmentFactory: any;

  isOpened:          boolean;
  constructor($scope, $location, ErrFactory, LoaderFactory, AssignmentFactory) {
    Object.defineProperties(this, {
      $scope:            { value: $scope            },
      $location:         { value: $location         },
      errFactory:        { value: ErrFactory        },
      loaderFactory:     { value: LoaderFactory     },
      assignmentFactory: { value: AssignmentFactory }
    });
  }

  changeLocationWithEBS(event, to): Promise<any> {
    event.stopPropagation();
    this.loaderFactory.show();
    return this.assignmentFactory.getOwn()
    .then((jobs) => {
      if (jobs.length) return this.$location.path(to);
      else return Promise.reject(new this.errFactory.NoEBS)
    })
    .catch((err) => {
      if (err instanceof this.errFactory) err.notify();
      else console.error(err);
    })
    .then(() => this.loaderFactory.hide());
  }

  toggle(): void {
    this.isOpened ? this.close(): this.open();
  }

  private open(): void {
    let folder = document.getElementsByTagName('ar-root')[0].getElementsByClassName('animate-height')[0];
    let height = [...<any>folder.children].reduce((sum, val) => sum = sum + +val?.children[0]?.offsetHeight, 0);
    folder.style.height = height + 'px';
    this.isOpened = true;
  }

  private close(): void {
    let folder   = document.getElementsByTagName('ar-root')[0].getElementsByClassName('animate-height')[0];
    folder.style.height = '0px';
    this.isOpened = false;
  }

}

window.app.component('arRoot', {
  template: require('scripts/components/time-trackings/ar-root/ar-root.html'),
  controller: ['$scope', '$location', 'ErrFactory', 'LoaderFactory', 'AssignmentFactory', ActivityReportsRoot]
});
